/**
 * 登录登出模块接口
 */
import axiosApi from '../../modules/axios-api'
import { MAIN_MICRO_SERVICE_PREFIX } from '../../index'
// 分页查询
export function querySaPagination(params) {
  return axiosApi.get(`${ MAIN_MICRO_SERVICE_PREFIX }/serviceNavi/page`, params)
}
// 新增公告
export function addSa(data) {
  return axiosApi.post(`${ MAIN_MICRO_SERVICE_PREFIX }/serviceNavi/add`, data)
}
// 编辑公告
export function editSa(data) {
  return axiosApi.post(`${ MAIN_MICRO_SERVICE_PREFIX }/serviceNavi/edit`, data)
}
// 公告详情
export function sADetail(params) {
  return axiosApi.get(`${ MAIN_MICRO_SERVICE_PREFIX }/serviceNavi/getById`, params)
}
// 批量删除
export function batchNoteDel(data) {
  return axiosApi.post(`${ MAIN_MICRO_SERVICE_PREFIX }/serviceNavi/remove`, data)
}
